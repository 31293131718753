import request from '@/utils/request'
const controller = '/RecruitJob'

const getRecruitJobPageList = params =>
  request({
    url: `${controller}/GetPageList`,
    method: 'get',
    params
  });
const getRecruitJobInfo = id =>
  request({
    url: `${controller}/GetInfo`,
    method: 'get',
    params: { id }
  });

export {
  getRecruitJobPageList,
  getRecruitJobInfo
}