var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"job-container"},[_vm._m(0),_c('div',{staticClass:"g-section"},[_c('div',{staticClass:"g-wrap mt-20 p-20",staticStyle:{"border-radius":"8px 8px 0px 0px"}},[_c('el-input',{staticClass:"mr-10 input-with-select",attrs:{"placeholder":"请输入关键字搜索"},model:{value:(_vm.queryData.keyword),callback:function ($$v) {_vm.$set(_vm.queryData, "keyword", $$v)},expression:"queryData.keyword"}}),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-search"},on:{"click":_vm.search}},[_vm._v("搜索")])],1)]),_c('div',{staticClass:"g-section"},[_c('div',{staticClass:"g-wrap flex-column pr-20 pl-20 pb-20 border-bottom"},[_c('div',{class:("" + (_vm.isExpand ? 'condition-section' : 'condition-section-hide'))},[_c('div',{staticClass:"job-condition"},[_c('span',{staticClass:"job-condition-title"},[_vm._v("所属城市:")]),_c('div',{staticClass:"job-condition-wrap"},[_c('span',{staticClass:"job-condition-item",class:{ checked: _vm.queryData.City === '' },on:{"click":_vm.handleAreaAll}},[_vm._v("全国")]),_vm._l((_vm.areaAllList),function(item,index){return _c('span',{key:index,staticClass:"job-condition-item",class:{
                checked: _vm.queryData.City == item.Code
              },on:{"click":function($event){return _vm.handleAreaItem(item.Code)}}},[_vm._v(_vm._s(item.Name))])})],2),_c('span',{staticClass:"job-condition-more",on:{"click":function($event){_vm.AreaDialogVisible = true}}},[_vm._v("更多+")])]),_c('div',{staticClass:"job-condition"},[_c('span',{staticClass:"job-condition-title"},[_vm._v("期望行业:")]),_c('div',{staticClass:"job-condition-wrap",style:({ height: _vm.isIndustryMore ? 'auto' : '27px' })},[_c('span',{staticClass:"job-condition-item",class:{ checked: _vm.queryData.Industry === '' },on:{"click":_vm.handleIndustryAll}},[_vm._v("不限")]),_vm._l((_vm.IndustryOptions),function(ref){
              var label = ref.label;
              var value = ref.value;
return _c('span',{key:value,staticClass:"job-condition-item",class:{
                checked: _vm.queryData.Industry === value
              },on:{"click":function($event){return _vm.handleIndustryItem(value)}}},[_vm._v(_vm._s(label))])})],2),_c('span',{staticClass:"job-condition-more",on:{"click":function($event){_vm.isIndustryMore = !_vm.isIndustryMore}}},[_vm._v(_vm._s(_vm.isIndustryMore ? "关闭" : "更多+"))])]),_c('div',{staticClass:"job-condition"},[_c('span',{staticClass:"job-condition-title"},[_vm._v("公司性质:")]),_c('div',{staticClass:"job-condition-wrap"},[_c('span',{staticClass:"job-condition-item",class:{ checked: _vm.queryData.Nature == '' },on:{"click":_vm.handleNatureAll}},[_vm._v("不限")]),_vm._l((_vm.NatureOptions),function(ref){
              var label = ref.label;
              var value = ref.value;
return _c('span',{key:value,staticClass:"job-condition-item",class:{
                checked: _vm.queryData.Nature === value
              },on:{"click":function($event){return _vm.handleNatureChange(value)}}},[_vm._v(_vm._s(label))])})],2)]),_c('div',{staticClass:"job-condition"},[_c('span',{staticClass:"job-condition-title"},[_vm._v("公司规模:")]),_c('div',{staticClass:"job-condition-wrap"},[_c('span',{staticClass:"job-condition-item",class:{ checked: _vm.queryData.Scale == '' },on:{"click":_vm.handleScaleAll}},[_vm._v("不限")]),_vm._l((_vm.ScaleOptions),function(ref){
              var label = ref.label;
              var value = ref.value;
return _c('span',{key:value,staticClass:"job-condition-item",class:{
                checked: _vm.queryData.Scale === value
              },on:{"click":function($event){return _vm.handleScaleChange(value)}}},[_vm._v(_vm._s(label))])})],2)])])])]),_c('div',{staticClass:"g-section mb-20"},[_c('div',{staticClass:"g-wrap flex-column p-0",staticStyle:{"border-radius":"0px 0px 8px 8px"}},[(_vm.list.length > 0)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"talent-list"},[_c('job-card',{attrs:{"cardList":_vm.list}}),(_vm.list.length >= 12)?_c('div',{staticClass:"loading"},[_c('Pagination',{attrs:{"total":_vm.totalCount,"limit":12,"layout":'prev, pager, next',"autoScroll":false},on:{"pagination":_vm.areaPagination}})],1):_vm._e()],1):_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"talent-list"},[_c('div',{staticClass:"noListData"},[_vm._v("当前无数据~")])])])]),_c('el-dialog',{attrs:{"title":"选择城市","visible":_vm.AreaDialogVisible,"width":"50%","before-close":_vm.areaHandleClose},on:{"update:visible":function($event){_vm.AreaDialogVisible=$event}}},[_c('el-tabs',{staticStyle:{"height":"600px"},attrs:{"tab-position":'left'}},_vm._l((_vm.AreaOptions),function(item,index){return _c('el-tab-pane',{key:index,attrs:{"label":item.Name,"stretch":""}},[_c('div',{staticClass:"label-area"},_vm._l((item.children),function(el,k){return _c('div',{key:k,staticClass:"area-item"},[_c('span',{staticClass:"area-item-span",on:{"click":function($event){return _vm.handleAreaItem(el.Code, index)}}},[_vm._v(" "+_vm._s(el.Name))])])}),0)])}),1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"g-section"},[_c('div',{staticClass:"service-banner"},[_c('div',{staticClass:"news-tips"},[_c('div',[_vm._v("岗位招聘")]),_c('div',{staticClass:"news-tips-en"},[_vm._v("JOB RECRUITMENT")])])])])}]

export { render, staticRenderFns }